@media screen and (max-width: 600px) {
    .numbersContainer {
        padding: 2.4rem 0;
    }
}

.linkedCardTitle {
    font-size: 2.5rem;
}

.linkedCardDesc {
    font-size: 1.8rem;
    line-height: 2.8rem;
}

@media screen and (max-width: 600px) {
    .linkedCardTitle {
        font-size: 1.8rem;
    }

    .linkedCardDesc {
        font-size: 1.6rem;
        line-height: 2.4rem;
    }

    .smallLinkedCardImage {
        width: 25%;
        height: 20%;
    }
}
