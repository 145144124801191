.paypal {
    width: 240px;
    border-radius: 10rem;
    overflow: hidden;
    z-index: 1;
    height: 5rem;
}

.mobilePaypal {
    background-color: var(--neutral-black);
    width: 200px;
    min-height: 30px;
    border-radius: 9rem;
    overflow: hidden;
    height: 4.5rem;
}
