@media screen and (max-width: 600px) {
    .numbersContainer {
        padding: 2.4rem 0;
    }
}

.countText {
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 4.6rem;
}

@media screen and (max-width: 600px) {
    .countText {
        font-size: 2.2rem;
    }
}

.descriptionText {
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 2.8rem;
}

@media screen and (max-width: 600px) {
    .descriptionText {
        font-size: 1.6rem;
        line-height: 2.6rem;
    }
}
