.chromeHelpVideoContainer {
    width: 51vw;
    height: 36vw;
}
@media screen and (max-width: 600px) {
    .chromeHelpVideoContainer {
        width: 87vw;
        height: 63vw;
    }
}
