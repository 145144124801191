.imageButton {
    cursor: pointer !important;
    border: 1px dashed var(--color-neutral-1);
}

.imageButton:hover {
    border: 1px solid var(--color-neutral-black);
}

.uploadFileText {
    color: var(--color-primary);
    cursor: pointer;
}

.uploadFileText:hover {
    color: var(--color-neutral-black);
}
