.container {
    background-color: transparent;
}

.container:hover {
    outline: 1px solid var(--color-neutral-black);
}

.container::placeholder {
    color: var(--color-neutral-black);
}
