/* main container */
.videoSumMainContainer {
    padding: 8rem 0;
    align-items: center;
}

@media screen and (max-width: 600px) {
    .videoSumMainContainer {
        padding: 4rem 0;
    }
}

/* Video with Linked Cards */
.learnbyTextingVideoContainer {
    width: 90vw;
    max-width: 90rem;
    height: 46.6rem;
}

@media screen and (max-width: 900px) {
    .learnbyTextingVideoContainer {
        width: 100%;
        height: 18.6rem;
    }
}

.videoCardContainer {
    padding: 6rem 0rem;
}

@media screen and (max-width: 600px) {
    .videoCardContainer {
        padding: 2.4rem;
        width: 100%;
    }
}

.videoCardTitle {
    font-size: 2.5rem;
}

.videoCardSubtitle {
    font-size: 1.8rem;
}

@media screen and (max-width: 600px) {
    .videoCardTitle {
        font-size: 1.6rem;
    }

    .videoCardSubtitle {
        font-size: 1.6rem;
    }
}

.smallLinkedCardContainer {
    border-radius: 2rem;
    padding: 6rem;
    gap: 5.1rem;
}

@media screen and (max-width: 600px) {
    .smallLinkedCardContainer {
        border-radius: 2rem;
        padding: 2.4rem;
        gap: 2.4rem;
    }
}

/* Feature card */
.featureVideoContainer {
    height: 24rem;
    width: 100%;
    margin-top: 2.9rem;
}

@media screen and (max-width: 900px) {
    .featureVideoContainer {
        width: 100%;
        height: 21.5rem;
        margin-top: 2.4rem;
        max-width: 35rem;
    }
}

@media screen and (max-width: 900px) {
    .mobileVideoSummarizer {
        width: 100%;
        height: 60%;
        margin-top: 4.2rem;
    }
}

/* Do more less time section */
.doMoreLeftImageStyle {
    width: 100%;
    height: 90%;
    margin: 2.9rem 0 5rem;
}

.pdfSummarizerImageContainer {
    position: absolute;
    bottom: -1rem;
    right: -4rem;
}

@media screen and (max-width: 900px) {
    .doMoreLeftImageStyle {
        margin: 0;
        height: 40rem;
    }

    .pdfSummarizerImageContainer {
        margin-top: 5rem;
        position: relative;
        border-radius: 0;
    }

    .pdfSummarizerImage {
        width: 100%;
        height: 100%;
        border-radius: 0 2rem 0 0;
    }
}

.kaiSherlockImage {
    margin-top: -2rem;
    margin-bottom: -6rem;
}

@media screen and (max-width: 900px) {
    .kaiSherlockImage {
        width: 60%;
        height: 50%;
        margin-top: 0;
        margin-bottom: -7rem;
    }
}

/* users feedbacks */
.feedbackContainer {
    padding: 8rem 0 0;
    align-items: center;
}

@media screen and (max-width: 600px) {
    .feedbackContainer {
        padding: 4rem 0;
    }
}

.feedbacksHeader {
    margin-bottom: 3.2rem;
}

@media screen and (max-width: 900px) {
    .feedbacksHeader {
        margin-bottom: 0;
    }
}

.feedbacksListContainer {
    gap: 6.4rem;
    max-width: 119.4rem;
    padding-bottom: -12rem;
}

@media screen and (max-width: 900px) {
    .feedbacksListContainer {
        margin-bottom: 2.4rem;
        gap: 3.2rem;
    }
}

/* explore section */
.examCardContainer {
    background-color: var(--color-card-primary);
    transition: background-color transform 0.2s ease-in-out;
}

.examCardContainer:hover {
    background-color: var(--color-neutral-1);
}

.categoryGrid {
    grid-template-columns: 1fr 1fr;
}

@media (max-width: 1125px) {
    .categoryGrid {
        grid-template-columns: 1fr;
    }
}
