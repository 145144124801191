.container {
    /** must match with richEditor.css .ProseMirror --editor-side-padding */
    --editor-side-padding: 64px;
    padding: 0 3.6rem 3rem;
}

@media screen and (max-width: 600px) {
    .container {
        --editor-side-padding: 20px;
        padding: 0 2rem 3rem;
    }
}
