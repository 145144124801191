.container {
    min-height: calc(99vh - var(--navbar-height));
    position: relative;
    padding: 2.4rem 3.6rem 7.2rem 3.6rem;
    flex: 1;
    background-color: var(--color-neutral-white);
}

@media screen and (max-width: 900px) {
    .container {
        padding: 2.4rem 5rem 7.2rem 5rem;
    }
}

@media screen and (max-width: 600px) {
    .container {
        padding: 2.4rem 2rem 5rem 2rem;
    }
}

.publishedText {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
}

@media screen and (max-width: 900px) {
    .publishedText {
        font-size: 16px;
        font-weight: 600;
        line-height: 26px;
    }
}

.title {
    font-size: 50px;
    font-weight: 700;
    line-height: 75px;
}

@media screen and (max-width: 900px) {
    .title {
        font-size: 40px;
        font-weight: 700;
        line-height: 52px;
    }
}

.contentContainer {
    margin-bottom: 8rem;
}

.contentContainer h1,
.contentContainer h2,
.contentContainer h3,
.contentContainer h4,
.contentContainer h5,
.contentContainer h6 {
    scroll-margin-top: calc(2rem + var(--navbar-height));
}
