.optionTemplateColumn {
    grid-template-columns: 1fr 1fr;
}
@media screen and (max-width: 600px) {
    .optionTemplateColumn {
        grid-template-columns: 1fr;
    }
}

.imageContainer {
    max-height: 300px;
    height: auto;
    max-width: 500px;
    width: 500px;
}

@media screen and (max-height: 875px) and (min-width: 600px) {
    .imageContainer {
        max-height: 250px;
        height: auto;
        max-width: 350px;
        width: 350px;
    }
}

@media screen and (max-height: 750px) {
    .imageContainer {
        max-height: 200px;
        height: auto;
        max-width: 400px;
        width: fit-content;
    }
}

@media screen and (max-height: 600px) {
    .imageContainer {
        max-height: 200px;
        height: auto;
        max-width: 400px;
        width: fit-content;
    }
}

@media screen and (max-width: 600px) {
    .imageContainer {
        max-height: 200px;
        height: auto;
        max-width: 400px;
        width: fit-content;
    }
}
