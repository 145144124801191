.searchContainer {
    display: flex;
    padding: var(--spacing-XS);
    border-radius: var(--border-radius-shortInput);
    border: 1px solid var(--theme-colors-neutralBlack);
    align-items: center;
    cursor: pointer;
}

.animatedSearchInput {
    background-color: transparent;
    border: none;
    padding: 0 var(--spacing-XS);
    color: var(--theme-colors-neutralBlack);
    font-family: var(--knowt-font);
    outline: none;
    transition: width 0.3s ease-in-out, opacity 0.3s ease-in-out;
    width: 0;
    opacity: 0;
}

.extended {
    width: 150px;
    opacity: 1;
    padding-left: 1rem;
}

.collapsed {
    width: 0;
    opacity: 0;
}

@media screen and (max-width: 1200px) {
    .hideOnResponsive {
        display: none;
    }
}
