.title {
    font-size: 4.4rem;
    font-weight: 800;
    cursor: text;
    outline: none;
    width: 100%;
    background-color: transparent;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
        "Open Sans", "Helvetica Neue", sans-serif;
    resize: none;
    border: none;
    padding: 0;
    margin: 0;
    color: var(--color-neutral-black);
}

.title[disabled] {
    color: var(--color-neutral-black);
}

.title::placeholder {
    content: attr(placeholder);
    display: block;
    color: var(--placeholder-grey);
    opacity: 1;
}
