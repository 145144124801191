.container {
    padding: 0 4rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
        "Open Sans", "Helvetica Neue", sans-serif;
    background-color: white;
}

.logo {
    display: flex;
    justify-content: flex-start;
    padding-right: 2rem;
}

.title {
    margin: 2rem 0;
    font-size: 4rem;
    font-weight: 800;
}

.table {
    border-collapse: collapse;
    width: 100%;
}

.row {
    border-bottom: 1px solid black;
}

.row:first-child {
    border-top: 1px solid black;
}

.cell {
    width: 50%;
    background: transparent;
    padding: 1rem;
}

.printedBodyClass {
    background-color: white;
}
