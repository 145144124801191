.settingsPageContainer {
    padding-block-start: 4rem;
    padding-block-end: 4rem;
    padding-inline: 4.6rem;
}

.headerPadding {
    padding: 3rem 3.6rem 2rem;
}

.inputLabel {
    font-weight: 700;
    font-size: 1.6rem;
    padding-top: 0.5rem;
    margin-right: 1.8rem;
}

.settingsContainer {
    padding: 3rem 4.4rem;
    width: 100%;
    border-radius: 2rem;
}

@media (max-width: 600px) {
    .settingsPageContainer {
        padding-inline: 0;
        padding-block-end: 0;
    }

    .settingsInlineHeader {
        padding-inline: 1.6rem;
    }

    .settingsContainer {
        width: 100%;
        padding: 3rem 2rem;
        border-radius: 0;
    }

    .headerPadding {
        padding: 3rem 2rem 2rem;
    }
}
