@media screen and (max-width: 1000px) {
    .stateColumn,
    .licenseColumn {
        display: none !important;
    }
}

@media screen and (max-width: 800px) {
    .dashboardContainer {
        max-width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        position: relative;
    }
    .tableContainer {
        padding-top: 3rem;
        transform: scale(0.95);
        transform-origin: top left;
    }
}
@media screen and (max-width: 780px) {
    .tableContainer {
        transform: scale(0.85);
    }
}
@media screen and (max-width: 700px) {
    .tableContainer {
        transform: scale(0.75);
    }
    .memberTableContainer {
        transform: scale(0.95);
        transform-origin: top left;
        position: absolute;
    }
}

@media screen and (max-width: 600px) {
    .tableContainer {
        transform: scale(0.65);
    }

    .memberTableContainer {
        transform: scale(0.9);
    }
}

@media screen and (max-width: 570px) {
    .memberTableContainer {
        transform: scale(0.8);
    }
}

@media screen and (max-width: 500px) {
    .tableContainer {
        transform: scale(0.45);
    }

    .memberTableContainer {
        transform: scale(0.58);
    }
}
