.userContentCard {
    background-color: var(--color-current-card);
    transition: background-color 0.14s ease;
}

.userContentCard:hover {
    outline: 1px solid var(--color-neutral-black);
    outline-offset: -1px;
}

.carouselColorsInit {
    --color-current-card: var(--color-neutral-white);
    --color-current-card-hover: var(--color-neutral-1);
    --color-current-pill: var(--color-background);

    --color-flashcardSet-pill: var(--color-flashcards-light);
    --color-note-pill: var(--color-docs-light);

    --color-assignment-pill: var(--color-assignment-light);
    --color-pdf-pill: var(--color-pdf-light);
    --color-video-pill: var(--color-video-light);
    --color-excel-pill: var(--color-excel-light);
    --color-ppt-pill: var(--color-ppt-light);
}

/**
 * When the previewer is not opened, but the card is hovered, do:
 * 1. card's type-pill color change to respective item type
 */
.userContentCard.flashcardSet:hover {
    --color-flashcardSet-pill: var(--color-flashcards-med);
}

.userContentCard.note:hover {
    --color-note-pill: var(--color-docs-med);
}

/* SWAP TO PROPER COLOR */
.userContentCard.assignment:hover {
    --color-assignment-pill: var(--color-assignment-med);
}

.userContentCard.pdf:hover {
    --color-pdf-pill: var(--color-pdf-med);
}

.userContentCard.video:hover {
    --color-video-pill: var(--color-video-med);
}

.userContentCard.excel:hover {
    --color-excel-pill: var(--color-excel-med);
}

.userContentCard.ppt:hover {
    --color-ppt: var(--color-ppt-med);
}

/**
 * When the previewer is opened for the current card, do:
 * 1. card's background change to respective item type
 * 2. card's hover color is the same as card's current background (in other word: no hover)
 * 3. card's type-pill color change to be darker
 * 4. card's pill color is the same as type-pill color
 * NOTE: for now only flashcard set and note that can be in preview
 */
.userContentCard.flashcardSet.inView {
    --color-current-card: var(--color-flashcards-light);
    --color-current-card-hover: var(--color-flashcards-light);
    --color-current-pill: var(--color-flashcards-med);
    --color-flashcardSet-pill: var(--color-flashcards-med);
}

.userContentCard.note.inView {
    --color-current-card: var(--color-docs-light);
    --color-current-card-hover: var(--color-docs-light);
    --color-current-pill: var(--color-docs-med);
    --color-note-pill: var(--color-docs-med);
}

/* SWAP TO PROPER COLOR */
.userContentCard.assignment.inView {
    --color-current-card: var(--color-docs-light);
    --color-current-card-hover: var(--color-docs-light);
    --color-current-pill: var(--color-docs-med);
    --color-assignment-pill: var(--color-docs-med);
}
