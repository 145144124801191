.flex {
    display: flex;
}

.flexRow {
    flex-direction: row;
}

.flexColumn {
    flex-direction: column;
}

.alignItemsCenter {
    align-items: center;
}

.justifyContentCenter {
    justify-content: center;
}
