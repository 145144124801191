.containerWrapper {
    container-type: inline-size;
}

.container {
    align-items: center;
    gap: 3rem;
    border-radius: 2rem;
    justify-content: space-between;
    width: 100%;
    padding: 2.4rem;
    text-align: left;
}

.labelInline {
    display: inline;
    white-space: nowrap;
}

.labelBottom {
    display: none;
}

.ratingContainer {
    width: auto;
}

.floatingFeedbackSelector {
    background-color: var(--color-neutral-black);
    color: var(--color-neutral-white);
    border-radius: 5.6rem;
    padding: 1rem 3rem;
}

.floatingFeedbackSelectorVertical {
    transform: scale(0.8) !important;
}

.floatingFeedbackSelectorTitle {
    min-width: 30rem;
}

@media screen and (max-width: 850px) {
    .floatingFeedbackSelectorH {
        transform: scale(0.8) !important;
    }
}

@media screen and (max-width: 650px) {
    .floatingFeedbackSelector {
        transform: scale(0.6) !important;
    }

    .floatingFeedbackSelectorTitle {
        min-width: 20rem;
        font-size: 1.6rem;
    }
}

@container (max-width: 750px) {
    .container {
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        padding: 1.6rem;
        text-align: center;
    }
}

@container (max-width: 605px) {
    .labelInline {
        display: none;
    }
    .labelBottom {
        display: inline;
    }
    .ratingContainer {
        width: 90%;
    }
}
