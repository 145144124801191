.header {
    text-align: center;
    font-size: 2.5rem;
}

.header:first-child {
    text-align: left;
    width: 30%;
    /* TODO change width for teachers */
    /* width: 45%; */
}

.row {
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 1.8rem;
    font-weight: 600;
}

.row:first-child {
    text-align: left;
}

.row span:first-child {
    width: 22rem;
    display: inherit;
}

.lastColumn {
    background-color: #092522;
    color: #fff;
}

.borderTop {
    border-radius: 2rem 2rem 0 0;
}

.borderBottom {
    border-radius: 0 0 2rem 2rem;
}
