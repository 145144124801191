.instructionsContainer {
    width: 43rem;
}

.instruction {
    width: auto;
}

@media screen and (max-width: 600px) {
    .instructionsContainer {
        width: 40rem;
        margin-bottom: 4rem;
    }

    .instruction {
        width: 100%;
    }
}
