.controlSectionGap {
    justify-content: center;
    gap: 1.8rem;
}

@media screen and (max-width: 900px) {
    .controlSectionGap {
        flex-wrap: wrap;
        justify-content: center;
    }
}

@media screen and (max-width: 600px) {
    .centerOnMobile {
        width: 80%;
        text-align: center;
    }
}

@media screen and (max-width: 712px) {
    .hideableControlButtons {
        display: none !important;
    }
}

@keyframes SlideFromBottom {
    0% {
        transform: translateY(0);
        /* TODO: consider adding back the animation */
        /* transform: translateY(100%); */
    }

    100% {
        transform: translateY(0);
    }
}

.recentlyStudied button {
    animation: SlideFromBottom 0.3s linear;
    animation-fill-mode: both;
}

.recentlyStudied button:nth-child(1) {
    animation-delay: 0.1s;
}
.recentlyStudied button:nth-child(2) {
    animation-delay: 0.3s;
}
.recentlyStudied button:nth-child(3) {
    animation-delay: 0.5s;
}
.recentlyStudied button:nth-child(4) {
    animation-delay: 0.9s;
}
