.flashcard {
    background-color: var(--color-neutral-white);
    position: relative;
    backface-visibility: hidden;
    transform-style: initial;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 1.8rem;
    overflow-y: auto;
    padding: 3.3rem 3.3rem;
    z-index: 30;
}
