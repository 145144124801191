.containerAll {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 2rem 8rem 16rem 8rem;
}

.containerTop {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: -8rem;
}

.containerText {
    width: 50%;
    max-width: 700px;
    margin-right: 5rem;
}

.containerButton {
    display: flex;
    align-self: flex-start;
    margin-top: 2rem;
    align-items: center;
}

.title {
    font-size: 4rem;
    font-weight: 600;
    color: #50d2c2;
    width: auto;
}

.containerImage {
    margin-top: 6rem;
}

.image {
    max-width: 700px;
    width: 55%;
    height: auto;
}

.containerCardList {
    display: flex;
    align-self: center;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 4rem;
    width: 100%;
    height: 26rem;
    max-width: 136rem;
    margin-bottom: 8rem;
}

.containerCardItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 1.6rem;
    padding: 3.6rem 2.6rem;
    min-height: 100%;
    width: 26%;
    border-radius: 15px;
}

.cardTitle {
    font-size: 2rem;
    font-weight: 700;
}

.cardDescription {
    font-size: 1.6rem;
    font-weight: 500;
}

.cardUnderContent {
    display: flex;
    flex: 1;
    align-items: flex-end;
    flex-direction: row;
    column-gap: 0.5rem;
    cursor: pointer;
}

.cardUnderText {
    display: flex;
    font-size: 1.8rem;
    font-weight: 600;
}

.benefitContainer {
    display: flex;
    flex-direction: row;
    margin: 1.4rem 0;
}

.emojiContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4.5rem;
    width: 4.5rem;
    border-radius: 15rem;
    margin-right: 1rem;
}

.emoji {
    font-size: 2.5rem;
}

.containerInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 8%;
    margin-top: 4rem;
}

.containerBenefit {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    column-gap: 8%;
    margin-top: 4rem;
}

.infoImage {
    width: 40%;
    max-width: 500px;
}

.infoTitle {
    font-size: 3rem;
    font-weight: 600;
    width: 100%;
    max-width: 65rem;
}

.infoDescription {
    font-size: 2rem;

    width: 100%;
    max-width: 50rem;
    margin-top: 1rem;
}

.openPositionTitle {
    font-size: 3rem;
    font-weight: 600;
}

.openPositionDescription {
    font-size: 2rem;

    font-weight: 500;
    margin-top: 1.8rem;
    margin-bottom: 3rem;
}

.openPositionListContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 14rem;
}

.openPositionItemContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 2.2rem;
    width: 90%;
    height: 14rem;
    max-width: 120rem;
    border-radius: 20px;
    padding: 0 4rem;
}

.openPositionJob {
    font-size: 2rem;
}

.openPositionSalary {
    margin-left: 2rem;
    font-size: 1.8rem;
    font-weight: 800;
}

.openPositionType {
    font-size: 1.8rem;
    font-weight: 800;
}

.openPositionLocal {
    font-size: 1.8rem;
    font-weight: 800;
    margin-right: 2rem;
}

.openPositionAreaContainer {
    width: 40rem;
    margin-right: 2rem;
}

.openPositionContentContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.openPositionRulesContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.openPositionButtonsContainer {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 1070px) {
    .openPositionDescription {
        text-align: center;
    }

    .openPositionJob {
        font-size: 3rem;
    }

    .openPositionAreaContainer {
        width: auto;
    }

    .openPositionListContainer {
        margin-top: 4rem;
    }

    .openPositionItemContainer {
        flex-direction: column;
        align-items: baseline;
        padding: 3rem;
        width: 86%;
    }

    .containerCardList {
        height: 35rem;
    }

    .containerCardItem {
        width: 25%;
    }

    .openPositionRulesContainer {
        margin-top: 0.5rem;
    }

    .openPositionButtonsContainer {
        align-self: flex-end;
    }

    .openPositionContentContainer {
        align-items: flex-start;
        flex-direction: column;
    }
}

@media screen and (max-width: 799px) {
    .containerAll {
        margin: 4rem 4rem 8rem 4rem;
    }

    .containerText {
        width: 100%;
        margin-right: 0;
        text-align: center;
    }

    .containerButton {
        justify-content: center;
    }

    .containerTop {
        margin-right: 0;
    }

    .title {
        font-size: 4.4rem;
    }

    .containerCardList {
        flex-direction: column;
        row-gap: 2rem;
        height: auto;
    }

    .containerCardItem {
        width: auto;
    }

    .containerInfo {
        flex-direction: column;
    }

    .containerBenefit {
        flex-direction: column;
    }

    .infoImage {
        width: 80%;
        max-width: 40rem;
        margin-bottom: 1.8rem;
    }

    .openPositionItemContainer {
        height: auto;
    }

    .openPositionJob {
        font-size: 2rem;
    }

    .openPositionSalary {
        font-size: 1.4rem;
        margin-left: 0;
    }

    .openPositionType {
        font-size: 1.4rem;
    }

    .openPositionLocal {
        font-size: 1.4rem;
    }

    .openPositionRulesContainer {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
        padding: 1rem 0rem;
    }
}

@media screen and (max-width: 600px) {
    .shouldAppearOnSmUp {
        display: none;
    }
}
