@media screen and (max-width: 1200px) {
    .mainContainer {
        margin-inline: 2rem !important;
    }
}

/**
 * - On bigger screens we let the content to take up to 127rem of the width only.
 */
.innerContainerWidth {
    max-width: 127rem;
}

/**
 * - Starting from IPad size we let the content to take up to 100% of the width.
 */
@media screen and (max-width: 1200px) {
    .innerContainerWidth {
        max-width: 100%;
    }
}
