@media screen and (max-width: 600px) {
    .fullWidthOnSmDown {
        width: 100% !important;
    }

    .overflowXOnSmDown {
        overflow-x: auto !important;
    }

    .flexStartOnSmDown {
        justify-content: flex-start !important;
    }
}

@media screen and (max-width: 900px) {
    .mdDownColumn {
        flex-direction: column !important;
    }
}

@media screen and (max-width: 600px) {
    .introRightTextContainer {
        padding-inline: 3rem;
    }

    .introIcon {
        width: 70%;
        height: 70%;
    }
}
