@media screen and (max-width: 900px) {
    .footerMdSideMarginAuto {
        margin-inline: auto !important;
    }

    .footerMdSidePadding {
        padding-inline: 10rem 0 !important;
    }

    .footerMdGridGap {
        gap: 4rem 1rem !important;
    }
}

@media screen and (max-width: 600px) {
    .footerSmItemLinkFontSize {
        font-size: 1.6rem !important;
    }

    .footerSmSidePadding {
        padding-inline: 2rem !important;
    }

    .footerSmGridTemplateColumns {
        grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr)) !important;
    }
}
