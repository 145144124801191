.reviewsSectionTopGapContainer {
    display: none;
}

@media screen and (max-height: 800px) and (min-width: 600px) {
    .reviewsSectionTopGapContainer {
        display: block;
    }

    .reviewsSection {
        display: none !important;
    }

    .popupPaper {
        margin-top: 25rem !important;
        margin-bottom: 25rem !important;
    }
}

@media screen and (max-height: 600px) and (min-width: 600px) {
    .popupPaper {
        margin-top: 45rem !important;
        margin-bottom: 45rem !important;
    }
}
