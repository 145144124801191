.settingsContainer {
    width: max(600px, 65%);
}

@media screen and (max-width: 600px) {
    .settingsContainer {
        width: 100%;
    }
}

.settingsOptionRawContainer {
    justify-content: space-between;
}

@media screen and (max-width: 600px) {
    .settingsOptionRawContainer {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.8rem;
    }
}
